import { createSettings } from '@codeleap/common'
import Obfuscate from './Obfuscate'
import * as Sentry from '@sentry/gatsby'

export const IsDevEnv = process.env.NODE_ENV === 'development'

const ENV = IsDevEnv ? 'development' : 'production'

const acceptedImageFormats = 'image/png, image/jpeg, image/jpg, image/heic, image/heif'

const FileInputPresets = {
  default: {
    accept: acceptedImageFormats,
  },
}

export const GATSBY_DEV_ENV = process.env.GATSBY_DEVELOPMENT_ENVIRONMENT === 'true'

const url = IsDevEnv ? 'https://api-kite.codeleap.co.uk/' : (
  GATSBY_DEV_ENV ? 'https://api-kite.codeleap.co.uk/' : 'https://prod.hurd.world/'
)

const LINKEDIN_REDIRECT = IsDevEnv ? 'http://localhost:8000/auth/linkedIn/' : (
  GATSBY_DEV_ENV ? 'https://hurd.codeleap.co.uk/auth/linkedIn/' : 'https://app.hurd.world/auth/linkedIn/'
)

if (!IsDevEnv) {
  Sentry.init({
    dsn: 'https://7bc74324c6084ea6a228ed0f0abc8792@o309198.ingest.sentry.io/4505479174160384',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [url],
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const _Settings = createSettings({
  GATSBY_DEV_ENV,
  AppName: 'Hurd',
  CompanyName: 'Kite Insights Limited',
  Description: 'Hurd Website',
  AppId: '6450721780',
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Website: {
    Development: 'https://hurd.codeleap.co.uk',
    Production: 'https://app.hurd.world',
    AppSite: 'https://hurd.world',
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Fetch: {
    ProductionURL: url,
    DevelopmentURL: url,
    ViewHTMLErrors: true,
  },
  Social: {
    FaceURL: 'https://www.facebook.com/hurd.world',
    LinkedinURL: 'https://www.linkedin.com/company/hurd/',
    LinkedinShareUrl: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    InstagramURL: 'https://www.instagram.com/hurd.world',
    XUrl: 'https://twitter.com/hurdworld',
  },
  ContactINFO: {
    Website: 'hurd.world',
    TermsAndPrivacy: 'https://hurd.world/privacy-policy',
    SupportEMAIL: 'support@hurd.world',
    ContactEMAIL: 'support@hurd.world',
    ContactPHONE: '+44 (0) 333 050 9420',
    CommunityGuidelines: 'https://hurd.world/community-guidelines',
    TermsOfUse: 'https://hurd.world/terms-of-use',
    PrivacyPolicy: 'https://hurd.world/privacy-policy',
    Help: 'https://hurd.world/help',
    About: 'https://hurd.world/about',
    Cookies: 'https://hurd.world/cookies',
    References: 'https://hurd.world/references',
  },
  Logger: {
    Level: 'debug',
    // TODO - get device or browser id
    DeviceIdentifier: '',
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      'new NativeEventEmitter',
      'User cancelled',
      'React does not recognize the',
      'Unknown event handler property',
      'forwardRef render functions accept exactly ',

    ],
    Obfuscate,
    ComponentLogging: true,
  },

  Slack: {
    echo: {
      icon: 'https://avatars.githubusercontent.com/u/48894125?s=200&v=4',
      token: 'xoxb-622265672359-1248324007429-Ts31vPT8jCNh7L99xtdbOgQB',
      channel: '#_dev_logs',
    },
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId: '960640478043-ieq0de54snm2i2f67m9caniidu570j3o.apps.googleusercontent.com',
    },
    Segment: {
      PROD: '4ZvT0CGPoCCaLkGq9oWICr3jkWwj2QWQ',
      DEV: 'cSZvDkZXHRML8hH3SAvY17yDLBMZecqP',
    },
    AppleSignIn: {
      ServiceClientId: 'hurd',
      RedirectURI: 'https://x-kite.firebaseapp.com/__/auth/handler',
    },
    FacebookSDK: {
      AppId: '1130448934268035',
    },
    LinkedIn: {
      ClientID: '77ob4zr1apb24a',
      ClientSecret: 'pJwmZ47mVJDjDunA',
      RedirectUri: LINKEDIN_REDIRECT,
      ORG_ID: '98195230',
    },
    Youtube: {
      API_KEY: 'AIzaSyAQTiUj0e_1gHeHaxdvFE3CHTY6DDot5jk',
    },
  },
  StoresLinks: {
    AppStore: 'https://hurd.world/',
    PlayStore: 'https://hurd.world/',
  },
  FileInputPresets,
  Images: {
    default: {
      aspect: 358 / 264,
      ruleOfThirds: true,
    },
    Stories: {
      aspect: 358 / 264,
      get minHeight() {
        return this.minWidth / this.aspect
      },
      minWidth: 100,
    },
    Avatar: {
      aspect: 1,
      ruleOfThirds: true,
      get minHeight() {
        return this.minWidth / this.aspect
      },
      minWidth: 100,
    },
  },
  EnableAnalytics: !!process.env.GATSBY_GA_MEASUREMENT_ID && !IsDevEnv && !GATSBY_DEV_ENV,
})

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndPrivacy) {
  _Settings.ContactINFO.TermsAndPrivacy = `https://codeleap.co.uk/dev/policies/?app=${Buffer.from(_Settings.AppName, 'base64')}`
}

const TESTING_ANALYTICS = true // change to disable

if (GATSBY_DEV_ENV && TESTING_ANALYTICS) {
  _Settings.EnableAnalytics = true
}

export const Settings = _Settings
