import { React, AppImages, Theme, useAppI18N, variantProvider } from '@/app'
import { View, Text, ActionIcon, Icon, Link, Button, OrganisationLogo, Tag, GradientModal, OrganisationDetails } from '@/components'
import { analytics } from '@/services'
import { Organisation } from '@/types'
import { formatOrganisationEmployees, SELF_EMPLOYED, shareOrganisation, useModal } from '@/utils'
import { TypeGuards, useBooleanToggle } from '@codeleap/common'
import { useMediaQuery } from '@codeleap/web'

type MyOrganisationSubHeaderProps = {
  organisation: Organisation
}

export const MyOrganisationSubHeader = ({ organisation }: MyOrganisationSubHeaderProps) => {
  const { t } = useAppI18N()
  const BREAKPOINT = 'small'

  const detailsModal = useModal()

  const mediaQuery = Theme.media.down(BREAKPOINT)
  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  const employees = formatOrganisationEmployees(organisation)
  const isSelfEmployed = employees === SELF_EMPLOYED

  const employeesText = employees && (isSelfEmployed ? (
    t('organisations.selfEmployed')
  ) : (
    t('organisations.employees', { employees })
  ))

  if (!organisation) return null

  const websiteURL = organisation.website.startsWith(`http`) ? organisation.website : `https://${organisation.website}`

  const hasDetails = organisation?.details?.trim?.()

  const handleOpenModal = () => {
    detailsModal.toggle()

    analytics.track('other_climate_credentials')
  }

  return (
    <>
      <View variants={['alignStart', 'justifySpaceBetween', 'fullWidth']} component='header'>
        <View variants={['column', 'gap:2', 'fullWidth']}>
          <View variants={['gap:4', 'alignCenter']} responsiveVariants={{ small: ['gap:3'] }}>
            <OrganisationLogo size='large' logo={organisation?.logo} alt={`${organisation?.name} logo`} />
            <View variants={['column', 'gap:0.5']} >
              <Text
                variants={['h0', 'color:neutral1', 'ellipsis']}
                responsiveVariants={{ [BREAKPOINT]: ['h2', 'color:neutral1'] }}
                style={styles.name}
                text={organisation?.name}
                component='h1'
              />

              <View variants={['alignCenter', 'gap:2']} responsiveVariants={{ [BREAKPOINT]: ['column', 'justifyStart', 'alignStart', 'gap:1'] }}>
                {
                  !TypeGuards.isNil(organisation?.category) && (
                    <Tag
                      text={organisation?.category?.label}
                      debugName='MyOrganisationSubHeader tag'
                      variants={['bg:primary4', 'text:neutral1']}
                      responsiveVariants={{ small: ['small'] }}
                    />
                  )
                }
                {
                  employeesText ? <View variants={['gap:1', 'alignCenter']}>
                    <Icon debugName={'MyOrganisationSubHeader users icon'} name='users' variants={['smaller', 'white']} />
                    <Text variants={['p3', 'color:neutral1']} text={employeesText} />
                  </View> : null
                }
                <Link style={styles.link} openNewTab to={websiteURL} tabIndex={0} variants={['p3', 'color:neutral1', 'center', 'gap:1']}>
                  <View variants={['gap:1', 'alignCenter']} responsiveVariants={{ small: ['marginTop:-0.5'] }}>
                    <Icon debugName={'MyOrganisationSubHeader external-link icon'} name='external-link' variants={['smaller', 'neutral1']} />
                    <Text variants={['p3', 'color:neutral1']} text={t('organisations.website')} />
                  </View>
                </Link>
              </View>
            </View>
          </View>
          {(isMobile && hasDetails) ? (
            <View variants={['justifyStart', 'alignCenter', 'fullWidth']}>
              <Button
                debugName={'Find out more button'}
                variants={['small', 'floating', 'flex', 'opaque']}
                text={t('organisations.findMore')}
                rightIcon={'chevron-right'}
                onPress={handleOpenModal}
              />
            </View>
          ) : null}
        </View>

        {isMobile ? null : <ActionIcon
          debugName={'MyOrganisationSubHeader share icon'}
          name='share'
          variants={['iconSize:3', 'minimal:neutral1', 'cursor:pointer']}
          tabIndex={0}
          onClick={() => {
            shareOrganisation(organisation)
          }}
        />}
      </View>
      <GradientModal
        toggle={detailsModal.toggle}
        visible={detailsModal.visible}
        onSharePress={() => shareOrganisation(organisation)}

      >
        <Text variants={['h2', 'color:neutral9']} text={t('organisations.informationTitle')} debugName='Organisation details text' />

        <OrganisationDetails
          wrapperless
          text={organisation.details}
        />
      </GradientModal>
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  link: {
    textDecorationColor: theme.colors.neutral1,
  },
  image: {
    width: '120px',
    height: '120px',
    borderRadius: theme.borderRadius.small,
  },
  name: {
    maxWidth: 'calc(50vw)',
  },
}), true)
