import React from 'react'
import { Theme, useAppI18N, variantProvider } from '@/app'
import { View, Text, Button, ActivityIndicator, ActionIcon } from '@/components'
import { arePropsEqual, TypeGuards, useMemo } from '@codeleap/common'
import { ScoreModal, ScoreNotFound } from './components/'
import { Organisation } from '@/types'
import { APIClient, analytics } from '@/services'
import { ProgressCircle } from '../Progress/Circle'
import { authWall, formatNumberToShort, formatScore, useIsMobile, useModal } from '@/utils'

type ClimateEngagementScoreProps = {
  variant?: 'modal' | 'withoutModal'
  organisation: Organisation
  modalOpen?: boolean
  toggleModal?: () => void
  showLastViewedText?: boolean
}

const ClimateEngagementScoreCP = (props: ClimateEngagementScoreProps) => {
  const { modalOpen, toggleModal, variant = 'modal', organisation, showLastViewedText = false } = props

  const _modal = useModal()

  const [visible, toggle] = TypeGuards.isFunction(toggleModal) && TypeGuards.isBoolean(modalOpen) ? [modalOpen, toggleModal] : [_modal.visible, _modal.toggle]
  const { t } = useAppI18N()

  const scoreRanks = APIClient.Answers.useScoreRanks()
  const { categories, subcategories } = APIClient.Organisations.useCategories()

  const isMobile = useIsMobile()

  const overviewData = useMemo(() => {
    const score = organisation?.score
    if (score && categories) {
      const formattedCategories = Object.keys(categories).reduce((acc, category) => {
        return {
          ...acc,
          [categories[category].name]: score.categories[category].total?.toFixed() || 0,
        }
      }, {})

      return { average_score: score.overall.toFixed(), ...formattedCategories }
    }
  }, [organisation?.score, categories])

  const BREAKPOINT = 'small'

  const hasModal = variant === 'modal'
  const wrapperStyles = hasModal ? styles.wrapper : styles.modalWrapper

  const isModal = variant === 'withoutModal'
  const score = organisation?.score

  if (!categories || !subcategories || !score) {
    return <View variants={['center', 'flex']}>
      <ActivityIndicator debugName={'ClimateEngagementScore Indicator'} />
    </View>
  }

  const isReviewsEmpty = !score.number_of_reviews

  const showOverviewText = !!score.last_review_date && !!score.number_of_reviews && !!score.number_of_reviewers

  const categoryKeys = Object.keys(score?.categories)

  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const lastViewed = organisation?.score?.last_review_date
    ? new Date(organisation?.score?.last_review_date)?.toLocaleDateString(undefined, options)
    : null

  if (isReviewsEmpty) {
    return <View variants={['alignCenter', 'justifyCenter']}>
      <View style={wrapperStyles}>
        <ScoreNotFound />
      </View>
    </View>
  }

  const organisationRank = scoreRanks.data?.find((rank) => score.overall >= rank.score)

  const onToggle = () => {
    if (!_modal.visible) {
      analytics.track('climate_empowerment_score', overviewData)
    }
    authWall(_modal.toggle)()
  }

  return (
    <>
      {hasModal && isMobile ? (
        <View variants={['alignCenter', 'justifySpaceBetween']}>
          <Text variants={['h3', 'color:neutral9']} responsiveVariants={{ [BREAKPOINT]: ['h2', 'color:neutral9'] }}>{t('organisations.scoreTitle')}</Text>
          {isMobile ? <ActionIcon
            name='arrow-right'
            variants={['minimal']}
            onPress={onToggle}
            debugName={'myOrganisation arrow-right icon'}
          /> : null}
        </View>
      ) : null}
      {hasModal && !isMobile ? (
        <Text variants={['h3', 'color:neutral9']}>{t('organisations.scoreTitle')}</Text>
      ) : null}
      <View variants={['alignCenter', 'justifyCenter', 'fullWidth']}>
        <View style={wrapperStyles}>
          <View variants={['column', 'gap:2', 'fullWidth']}>
            <View variants={['alignCenter', 'justifyCenter', 'gap:4', 'fullWidth']}>
              <View variants={['column', 'alignCenter', 'justifyCenter', isModal && 'flex']}>
                <ProgressCircle
                  progress={score.overall}
                  image={organisationRank?.web_image || organisationRank?.mobile_image}
                  variants={['noLabel', 'largeImage', 'large']}
                  text={organisationRank?.label}
                />
                <View variants={['column', 'alignCenter', 'justifyCenter']}>
                  <Text variants={['hx', 'color:neutral9']}>{formatScore(score.overall)}%</Text>
                  <Text variants={['p2', 'color:neutral5', 'marginTop:-1']} text={t('organisations.score.average')} />
                </View>
              </View>

              <View style={styles.verticalSeparator} />

              <View variants={['column', 'gap:2', 'alignCenter', 'justifyCenter', isModal && 'flex']}>
                {categoryKeys.map((categoryId) => {
                  const category = categories[categoryId]

                  const categoryScore = score.categories[categoryId].total

                  return (
                    <View variants={['gap:2', 'alignCenter']} key={categoryId}>
                      <ProgressCircle
                        progress={categoryScore}
                        image={category.web_icon || category.mobile_icon}
                        variants={['small', 'noLabel']}
                      />
                      <View variants={['column']}>
                        <Text variants={['h5', 'color:neutral5']}>{categoryScore ? `${formatScore(categoryScore)}%` : '0%'}</Text>
                        <Text variants={['p2', 'color:neutral9']}>{category.name}</Text>
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>

            <View
              variants={['column', 'justifyCenter', 'alignCenter', 'gap:2']}
              responsiveVariants={{ [BREAKPOINT]: ['column', 'justifyCenter', 'alignCenter', 'gap:2'] }}
            >
              {showOverviewText && !showLastViewedText && <Text variants={['p4', 'color:neutral5']}>
                {t(`organisations.score.${score.number_of_reviews !== 1 ? 'answers' : 'answer'}`, { nReviews: formatNumberToShort(score.number_of_reviews) })}{'\u00A0'}
                {t('from')}{'\u00A0'}
                {t(`organisations.score.${score.number_of_reviewers !== 1 ? 'reviewers' : 'reviewer'}`, { nReviewers: formatNumberToShort(score.number_of_reviewers) })}
              </Text>}
              {showLastViewedText && <Text variants={['p4', 'color:neutral5']}>
                {organisation?.score?.number_of_reviews} {t(`organisations.score.${organisation?.score?.number_of_reviews !== 1 ? 'lastReviews' : 'lastReview'}`, { nLastReviewed: lastViewed })}
              </Text>}
              {(hasModal && !isMobile) ? (
                <Button
                  variants={['minimal', 'color:primary3', 'noPadding', 'normalize']}
                  debugName={'Climate Empowerment Score details'}
                  rightIcon='expand'
                  text={t('organisations.moreDetail')}
                  onPress={onToggle}
                />
              ) : null}
            </View>
          </View>

          {hasModal ? <ScoreModal visible={visible} toggle={toggle} organisation={organisation} /> : null}

        </View>
      </View>
    </>
  )
}

export const ClimateEngagementScore = React.memo(ClimateEngagementScoreCP, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['organisation', 'modalOpen', 'toggleModal'],
  })
})

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: '460px',
  },
  modalWrapper: {
    width: '100%',
  },
  verticalSeparator: {
    width: 1,
    height: '90%',
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: theme.colors.neutral3,
  },
  placeholderCircle: {
    width: '130px',
    height: '130px',
    backgroundColor: theme.colors.primary3,
    borderRadius: theme.borderRadius.rounded,
  },
  tinyPlaceholderCircle: {
    width: '64px',
    height: '64px',
    backgroundColor: theme.colors.primary3,
    borderRadius: theme.borderRadius.rounded,
  },
}), true)
