import { React, Theme, variantProvider } from '@/app'
import { View, Text, Grid, OrganisationCard, SearchInput } from '@/components'
import { APIClient } from '@/services'
import { Organisation } from '@/types'
import { shareApp, useFlatlistProps, useIsMobile } from '@/utils'
import { onMount, useCallback, useI18N, useRef, useState } from '@codeleap/common'
import { useBreakpointMatch, useSearchParams } from '@codeleap/web'
import { navigate } from 'gatsby'
import { OrganisationNotFound } from './components/NotFound'
import { analytics } from '@/services'

const Placeholder = () => {
  return (
    <View variants={['fullWidth', 'alignCenter', 'justifyCenter']}>
      <OrganisationNotFound />
    </View>
  )
}

const SearchOrganisation = ({ setSearch, ...inputProps }) => {
  const { t } = useI18N()

  return (
    <SearchInput
      placeholder={t('organisations.searchInputPlaceholder.searchOrganisations')}
      variants={['noError']}
      onSearchChange={setSearch}
      debounce={800}
      debugName='Organisations:searchInput'
      {...inputProps}
    />
  )
}

export const OrganisationsWrapper = () => {
  const isSearching = useRef(false)
  const [params, setParams] = useSearchParams({
    name: '',
    share: '',
    currentOrg: '',
  })

  const organisations = APIClient.Organisations.organisationsManager.use({
    filter: {
      name: params?.name,
    },
  })

  const { profile } = APIClient.Session.useEdit()
  const organisation = profile?.organization

  const { t } = useI18N()

  const setSearchParam = (val) => {
    if (!isSearching.current && val.trim().length >= 1) {
      isSearching.current = true
      analytics.track('org_search', { source_from: 'find_org' })
    }
    setParams({
      ...params,
      name: val,
    })
  }

  const Header = useCallback(() => (
    <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}>
      <Text variants={['h1', 'color:neutral9']} style={styles.title}>{t('organisations.wrapperTitle')}</Text>

      <View style={styles.inputWrapper}>
        <SearchOrganisation
          setSearch={setSearchParam}
          onBlur={() => isSearching.current = false}
          defaultValue={params?.name}
        />
      </View>
    </View>
  ), [isSearching.current])

  const listProps = useFlatlistProps(organisations, {
    noMoreItemsText: t('organisations.noMoreItemsText'),
  })

  const isMobile = useIsMobile()

  const itemsSpacing = Theme.spacing.value(isMobile ? 2 : 4)

  const numColumns = useBreakpointMatch({
    'small': 1,
    'largeish': 2,
    'large': 3,
    'huge': 3,
  })

  onMount(() => {
    if (params?.share === 'true') {
      shareApp()
    }

    if (params?.currentOrg === 'true' && organisation?.id && !organisation?.is_unknown) {
      navigate(`${organisation?.id}`)
    }
  })

  const onPressOrg = (id: Organisation['id'], org_name: Organisation['name']) => {
    analytics.track('org_view', { org_name })
    navigate(`${id}`)
  }

  const renderItem = useCallback(({ item, index }) => <OrganisationCard key={item?.id + 'org'} tabIndex={index} onPress={() => onPressOrg(item?.id, item?.name)} organisation={item} />, [])

  return (
    <View
      variants={['column', 'gap:4']}
      responsiveVariants={{ small: ['gap:2'] }}
    >
      <Header />

      <Grid
        {...listProps}
        debugName={'Organisations:grid'}
        numColumns={numColumns}
        renderItem={renderItem}
        placeholder={{
          debugName: 'OrganisationsWrapper Placeholder',
          renderEmpty: Placeholder,
          loading: organisations?.isRefreshing || listProps?.isLoading || listProps?.loading || listProps?.isFetchingNextPage,
        }}
        columnItemsSpacing={itemsSpacing}
        rowItemsSpacing={itemsSpacing}
        // @ts-ignore
        data={organisations.items}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  inputWrapper: {
    width: '100%',
    maxWidth: '390px',

    [theme.media.down('small')]: {
      maxWidth: '100%',
    },
  },
  title: {
    [theme.media.down('small')]: {
      display: 'none',
    },
  },
}), true)
