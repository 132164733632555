import { React, variantProvider, I18N, Theme } from '@/app'
import { Text, View, Image, StoryAvatar, Button, Like, ReplyPreview, PlayerYoutube } from '@/components'
import { TouchableProps } from '@codeleap/web'
import { memo } from 'react'
import { CardBase } from './CardBase'
import { Story, Reply } from '@/types'
import { arePropsEqual, PropsOf } from '@codeleap/common'

type StoriesCardProps = {
  story: Partial<Story>
  onToggleLike: () => void
  onPress?: TouchableProps['onPress']
  showOrganisation?: boolean
  width?: number
  replies?: Reply[]
  asLink?: boolean
  href?: string
} & Partial<PropsOf<typeof CardBase>>

const IMAGE_HEIGHT = 244

const _StoriesCard = ({
  story,
  onToggleLike,
  showOrganisation,
  width,
  replies,
  asLink = false,
  href = `/learn/?section=successStories&storyId=${story?.id}`,
  ...props
}: StoriesCardProps) => {
  // perf.inspectRender('stories card')
  const headline = story?.headline
  const overview = story?.overview

  const likes = String(story?.likes?.count)
  const isLiked = story?.likes?.is_liked

  const picture = story?.picture

  const showReply = replies?.length > 0

  const storyImg = !!picture ? (
    <Image
      source={picture}
      style={styles.image}
      objectFit='cover'
      alt={'Story Card Image'}
      type={'dynamic'}
      variants={['fullWidth', 'border-radius:small']}
    />
  ) : null

  return (
    <CardBase
      debugName={'Search Organisation card onPress'}
      innerWrapperProps={{ variants: ['fullWidth'] }}
      tabIndex={0}
      variants={[
        'card:elevated',
        'maxFullWidth',
        'card:TouchableEffect',
        ...(props.variants || []),
      ]}
      gap={2}
      innerWrapperProps={{
        variants: ['fullWidth', 'gap:2', 'maxFullWidth'],
      }}
      css={{
        width,
      }}
      {...props}
      style={Theme.effects.light}
      onPress={null}
    >
      <StoryAvatar story={story} showOrganisation={showOrganisation} />

      {!!story?.video ? <PlayerYoutube.Preview video={story?.video} /> : storyImg}

      <View variants={['column', 'gap:0.5']}>
        <Text variants={['color:neutral10', 'bold', 'h4', 'wordBreak-word']} text={headline} component='h3' />

        <Text variants={['p1', 'wordBreak-word']} text={overview} />
      </View>

      {showReply && <ReplyPreview replies={replies} style={styles.replyPreview} />}

      <View variants={['row', 'alignCenter', 'justifySpaceBetween', 'fullWidth']}>
        <Like
          isLiked={isLiked}
          likesCount={Number(likes)}
          onToggleLike={onToggleLike}
          variants={['row', 'alignCenter', 'gap:1']}
          tabIndex={0}
        />

        <Button
          debugName='Read more about story'
          text={I18N.t('reusable.readMore')}
          variants={['color:primary3', 'marginLeft:auto', 'minimal', 'story:readMore']}
          onPress={props?.onPress}
          component={asLink ? 'a' : 'button'}
          href={href}
          style={{ textDecoration: 'none' }}
        />
      </View>
    </CardBase>
  )
}

export const StoriesCard = memo(_StoriesCard, (prev, next) => {
  return arePropsEqual(prev, next, {
    check: ['story'],
  })
}) as typeof _StoriesCard

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    image: {
      ...theme.presets.fullWidth,
      height: IMAGE_HEIGHT,
    },
    replyPreview: {
      ...theme.spacing.marginTop(-1),
    },
  }),
  true,
)
