import { PaginationResponse, QueryManager, ReactQuery, TypeGuards, useState } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { CategoriesResponse, CategoryDict, Organisation } from '@/types'

const BASE_URL = 'organizations/'

type OrganizationFilters = {
  name?: string
  show_unverified?: boolean
}

async function getUnknownOrganisation() {
  const response = await api.get<Organisation>(`${BASE_URL}unknown/`)

  return response.data
}

async function getOrganisationCategories() {
  const categoryResponse = await api.get<CategoriesResponse>(`questions/categories/`)
  const subCategoryResponse = await api.get<CategoryDict>(`questions/subcategories/`)

  const data = {
    categories: categoryResponse.data,
    subcategories: subCategoryResponse.data,
  }

  return data
}

const queryKeys = {
  unknownOrganisation: ['unknown-organisation'],
  categories: ['categories'],
}

export const organisationsManager = new QueryManager({
  itemType: {} as Organisation,
  name: 'organisations',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: OrganizationFilters) => {

    const response = await api.get<PaginationResponse<Organisation>>(BASE_URL, {
      params: {
        limit,
        offset,
        include_score: true,
        ...filters,
        name: filters.name?.trim() || undefined,
      },
    })

    return response.data
  },

  createItem: async (data) => {
    const response = await api.post(BASE_URL, data)

    return response.data
  },

  retrieveItem: async (id) => {
    if (TypeGuards.isNil(id)) return undefined
    const response = await api.get<Organisation>(`${BASE_URL}${id}/`, {
      params: {
        include_score: true,
      },
    })

    return response.data
  },

})

export const useUnknownOrganisation = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.unknownOrganisation,
    queryFn: () => {
      return getUnknownOrganisation()
    },
    onSuccess: (data) => {
      organisationsManager.updateItems(data)
    },
  })

  return {
    query,
    organisation: query.data,
  }
}

export const useCategories = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.categories,
    queryFn: () => {
      return getOrganisationCategories()
    },
  })

  return {
    query,
    categories: query.data?.categories,
    subcategories: query.data?.subcategories,
  }
}

export function getOrganisationInvolved() {
  return api.get(`${BASE_URL}get_involved/`)
}
