import { Text, View, Avatar, variants, Link } from '@/components'
import moment from 'moment'
import { AvatarStoryProps } from './types'
import { StoryAvatarStyles } from '@/app/stylesheets'
import { useDefaultComponentStyle, TypeGuards, useI18N } from '@codeleap/common'
import { useAppI18N } from '@/app'

export const StoryAvatar = (props: AvatarStoryProps) => {
  const { variants, styles, story, responsiveVariants, showOrganisation, ...rest } = props
  const { t } = useAppI18N()

  const created_datetime = story?.created_datetime
  const username = story?.author?.display_name
  const avatar = story?.author?.avatar
  const organisation = story?.organization?.name
  const organisationId = story?.organization?.id

  const date = moment(created_datetime).format('MMM, YYYY')

  const variantStyles = useDefaultComponentStyle<
    'u:StoryAvatar',
    typeof StoryAvatarStyles
  >('u:StoryAvatar', {
    variants,
    styles,
    responsiveVariants,
  })

  return (
    <View variants={['fullWidth', 'alignCenter']} css={variantStyles.wrapper}>
      <Avatar
        variants={['rounded', 'alignSelfStart', 'small']}
        debugName='PostAvatar'
        firstNameOnly
        name={username ?? t('learn.stories.unknownUserAvatar')}
        image={avatar}
        {...rest}
      />
      <View variants={['marginLeft:2', 'flex', 'column']} css={variantStyles.textsWrapper}>
        <Text text={username ?? t('learn.stories.unknownUser')} variants={['h5', 'color:neutral1']} css={variantStyles.textUsername} />
        <Text text={`${date}`} variants={['p4', 'color:neutral1']} css={variantStyles.textDate} />
        {!TypeGuards.isNil(organisation) && showOrganisation ? (
          <View css={variantStyles.organisationWrapper}>
            <Text variants={['p3']} text={t('reusable.about')} css={variantStyles.textOrganisationAbout} />
            <Link variants={['marginLeft:0.5']} css={variantStyles.textOrganisation} to={`/organisations/${organisationId}`} text={organisation} tabIndex={0} />
            {/* <Text variants={['marginLeft:0.5', 'p3']} text={organisation} css={variantStyles.textOrganisation} /> */}
          </View>
        ) : null}
      </View>
    </View >
  )

}
