import { assignTextStyle, useAppI18N, variantProvider } from '@/app'
import { useState } from 'react'
import { CardBase } from './CardBase'
import { Button, Text, View } from '..'

export const FAQCard = (props) => {
  const maxChar = 250
  const { faq } = props
  const { predicate, answer } = faq

  const [isExpanded, setIsExpanded] = useState(false)

  const showReadMoreBtn = !!answer && answer.length > maxChar
  const txt = isExpanded ? answer : (showReadMoreBtn ? answer.substring(0, maxChar).trim() + '...' : answer)

  const { t } = useAppI18N()

  return (
    <CardBase
      debugName='Organisation score card'
      variants={['backgroundColor:neutral1', 'padding:2', 'border-radius:small', 'card:elevated', 'fullWidth', 'review']}
      styles={{ wrapper: { maxWidth: '100%' } }}
    >
      <View variants={['fullWidth', 'column', 'gap:1']}>
        <Text text={predicate} variants={['h5', 'color:neutral6']} component='h3' />
        <View variants={['row', 'gap:0.4']}>
          <Text text={txt} variants={['p3', 'color:neutral6']} />
          {showReadMoreBtn && (
            <Button
              debugName={'Read more'}
              variants={['minimal', 'alignSelfEnd', 'marginTop:1']}
              text={isExpanded ? 'Menos' : t('reusable.readMore')}
              styles={{ wrapper: styles.readMoreButtonWrapper, text: styles.text }}
              onPress={() => setIsExpanded(!isExpanded)}
            />
          )}
        </View>
      </View>
    </CardBase>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  readMoreButtonWrapper: {
    padding: 0,
    marginTop: 0,
    height: '24',
    '&:hover': {},
  },
  text: {
    ...assignTextStyle('p2')(theme).text,
    color: theme.colors.primary3,
  },
}), true)
