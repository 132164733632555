import { Button, CardBase, ReplyPreview, Text, View } from '@/components'
import { ProgressCircle } from '../Progress/Circle'
import { IconProps } from '@codeleap/web'
import { formatDateLocalized, variantProvider } from '@/app'
import { arePropsEqual, PropsOf, useI18N } from '@codeleap/common'
import React from 'react'
import { Reply } from '@/types'

type ReviewCardProps = {
  title: string
  date: string
  text: string
  progress: number
  icon?: IconProps['name']
  replies?: Reply[]
  asLink?: boolean
  href?: string
} & Partial<PropsOf<typeof CardBase>>

const multiplier = 10
const maxChar = 280

export function ReviewCardComponent(props: ReviewCardProps) {
  const { title, date, text, progress, icon, onPress, replies, asLink = false, href, ...otherProps } = props
  const { t } = useI18N()
  const showReadMoreBtn = !!text && text?.length > maxChar
  const txt = showReadMoreBtn ? text?.substring(0, maxChar).trim() + '...' : text
  const showReply = replies?.length > 0

  const dateFormatted = formatDateLocalized(new Date(date), 'learn.stories.date_format')

  return (
    <CardBase
      debugName='Organisation score card'
      variants={['backgroundColor:neutral1', 'padding:2', 'border-radius:medium', 'card:elevated', 'fullWidth', 'review']}
      onPress={onPress}
      component={asLink ? 'a' : 'div'}
      href={href}
      {...otherProps}
    >
      <View variants={['fullWidth', 'column', 'gap:1']}>
        <View variants={['fullWidth', 'justifySpaceBetween', 'alignStart', 'gap:1']}>
          <View variants={['column']}>
            <Text variants={['h5', 'color:neutral6']} text={title} component='h3' />
            <Text variants={['color:neutral5', 'p4']} text={dateFormatted} />
          </View>

          <ProgressCircle variants={['tiny']} image={icon} progress={progress * multiplier} />
        </View>

        <View variants={['column', 'fullWidth']}>
          <Text
            variants={['p3']}
            text={txt}
            style={styles.text}
          />
          {showReadMoreBtn && <Button
            debugName={'Read more'}
            variants={['minimal', 'alignSelfEnd', 'marginTop:1']}
            text={t('reusable.readMore')}
            styles={{ wrapper: styles.buttonWrapper }}
            onClick={onPress}
            tabIndex={otherProps?.tabIndex + 0.1}
            href={href}
            component={asLink ? 'a' : 'button'}
          />}
        </View>

        {showReply && (
          <ReplyPreview replies={replies} />
        )}

      </View>
    </CardBase>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  text: {
    wordBreak: 'break-word',
  },
  buttonWrapper: {
    height: '24',
    textDecoration: 'none',
    '&:hover': {},
  },
}), true)

export const ReviewCard = React.memo(ReviewCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['title', 'date', 'text', 'progress', 'icon'],
  })
})
