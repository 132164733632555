import { I18N, IsDevEnv } from '@/app'
import { TypeGuards, createForm, getValidator, yup } from '@codeleap/common'
import { fakeProfile, testerOptions, testers } from '../testers'
export * from '../testers'

const initialState = IsDevEnv ? testers.default : undefined
const passwordValidationRegex = /^(?=.*[A-Z])(?=.*[0-9!@#$%^&*]).{8,}/

export const login = () => createForm('login', {
  email: {
    type: 'text',
    placeholder: 'email@email.com',
    validate: yup
      .string()
      .required()
      .email(),
    'aria-label': I18N.t('form.label.email'),
    'aria-required': true,
    defaultValue: initialState?.email,
    label: I18N.t('form.label.email'),
  },
  password: {
    type: 'text',
    password: true,
    'aria-label': I18N.t('form.label.name'),
    'aria-required': true,
    placeholder: I18N.t('form.placeholder.password'),
    label: I18N.t('form.label.password'),
    defaultValue: initialState?.password,
    description: I18N.t('form.description.password'),
    validate: yup
      .string()
      .required(),

  },
  remember: {
    type: 'checkbox',
    label: I18N.t('form.label.remember'),
    defaultValue: true,
  },
  tester: {
    type: 'select',
    label: 'Tester',
    options: testerOptions,
    defaultValue: 'default',
  },
})

const passwordValidation = () => yup.string()
  .required()
  .min(8)
  .max(128)
  .matches(passwordValidationRegex, I18N.t('form.description.password'))

const NAME_MAX_LENGTH = 30

export const createSignup = (withPassword = false, visible = true) => {
  const loginForm = login()

  return createForm('signup' + (visible ? '' : ':hidden'), {
    email: {
      ...loginForm.config.email,
      defaultValue: fakeProfile.email,
    },
    password: {
      ...loginForm.config.password,
      placeholder: I18N.t('form.placeholder.password'),
      label: I18N.t('form.label.password'),
      'aria-label': I18N.t('form.label.password'),
      'aria-required': true,
      defaultValue: fakeProfile.password,
      validate: withPassword ? passwordValidation() : undefined,
    },
    name: {
      type: 'text',
      label: I18N.t('form.label.name'),
      'aria-label': I18N.t('form.label.name'),
      'aria-required': true,
      defaultValue: fakeProfile.last_name,
      validate: yup.string().required(I18N.t('form.validations.required')).trim(I18N.t('form.validations.required'))
        .max(NAME_MAX_LENGTH, I18N.t('form.validations.max', { max: NAME_MAX_LENGTH })),
      placeholder: I18N.t('form.placeholder.fullName'),
    },
    repeatPassword: {
      ...loginForm.config.password,
      description: '\u00A0',
      label: I18N.t('form.label.confirmPassword'),
      'aria-label': I18N.t('form.label.confirmPassword'),
      'aria-required': true,
      defaultValue: fakeProfile.repeatPassword,
      validate: withPassword ? (repeatPassword, { password }) => {
        const isValid = repeatPassword === password
        return {
          valid: isValid,
          message: isValid ? '' : "Passwords don't match",
        }
      } : null,
    },
    terms: {
      type: 'checkbox',
      label: I18N.t('form.label.terms'),
      defaultValue: true,
      validate: (value) => {
        const accpeted = value
        return {
          valid: accpeted,
          message: accpeted ? '' : I18N.t('form.validations.terms'),
        }
      },
    },
    avatar: {
      type: 'file',
    },
    tester: loginForm.config.tester,
    receive_marketing_email: {
      type: 'checkbox',
      defaultValue: true,
      label: I18N.t('form.label.affiliates'),
    },
    remember: loginForm.config.remember,
  })
}

export const signup = (visible = true) => createSignup(true, visible)
export const socialSignup = (visible = true) => createSignup(false, visible)

export const forgotPassword = () => {
  const loginForm = login()
  return createForm('forgotPassword', {
    email: loginForm.config.email,
    tester: loginForm.config.tester,
  })
}

export const aboutYou = () => {
  return createForm('aboutYou', {
    gender: {
      label: I18N.t('form.label.gender'),
      placeholder: I18N.t('form.placeholder.selectOne'),
      type: 'select',
      validate: yup.string().required(),
      'aria-label': I18N.t('form.label.gender'),
      'aria-required': true,
    },
    country: {
      label: I18N.t('form.label.country'),
      placeholder: I18N.t('form.placeholder.selectOne'),
      type: 'select',
      validate: yup.string().required(),
      'aria-label': I18N.t('form.label.country'),
      'aria-required': true,
    },
    inbound_source: {
      type: 'select',
      label: I18N.t('form.label.inboundSource'),
      placeholder: I18N.t('form.placeholder.selectOne'),
      validate: yup.string().required(),
      'aria-label': I18N.t('form.label.inboundSource'),
      'aria-required': true,
    },
    inbound_source_details: {
      type: 'text',
      label: I18N.t('form.label.inboundSourceDetails'),
      placeholder: I18N.t('form.placeholder.inboundSourceDetails'),
    },
    climate_priority: {
      type: 'select',
      label: I18N.t('form.label.climate_priority'),
      placeholder: I18N.t('form.placeholder.climate_priority'),
      validate: yup.string().required(),
    },
  })
}

export const aboutJob = () => {
  return createForm('aboutJob', {
    department: {
      label: I18N.t('form.label.department'),
      placeholder: I18N.t('form.placeholder.selectOne'),
      type: 'select',
      validate: yup.string().required(),

    },
    seniority: {
      type: 'select',
      label: I18N.t('form.label.seniority'),
      placeholder: I18N.t('form.placeholder.selectOne'),
      validate: yup.string().required(),
    },
    organization: {
      type: 'select',
      label: I18N.t('form.label.organisation'),
      placeholder: I18N.t('form.placeholder.selectOne'),
      validate: (val, formValues) => {
        let value = val
        if (!TypeGuards.isNumber(val)) {
          value = val?.id
        }

        const validator = getValidator(yup.number().required(I18N.t('form.validations.required')))

        return validator(value, formValues)

      },
    },
  })
}

const MIN = 6
const MAX = 31
export const editProfile = () => {
  const signupForm = signup()
  const aboutYouForm = aboutYou()
  const aboutJobForm = aboutJob()

  return createForm('editProfile', {
    email: signupForm.config.email,
    display_name: {
      type: 'text',
      label: I18N.t('form.label.screenName'),
      validate: yup.string().required(I18N.t('form.validations.required')).trim(I18N.t('form.validations.required'))
        .min(MIN, I18N.t('form.validations.min', { min: MIN }))
        .max(MAX, I18N.t('form.validations.max', { max: MAX }))
        .verifyProfanity(),
      placeholder: I18N.t('form.placeholder.screenName'),
    },
    name: signupForm.config.name,
    organization: aboutJobForm.config.organization,
    department: aboutJobForm.config.department,
    seniority: aboutJobForm.config.seniority,
    inbound_source: aboutYouForm.config.inbound_source,
    inbound_source_details: aboutYouForm.config.inbound_source_details,
    gender: aboutYouForm.config.gender,
    country: aboutYouForm.config.country,
    receive_marketing_email: signupForm.config.receive_marketing_email,
    linkedin_url: {
      type: 'text',
      label: I18N.t('form.label.linkedinUrl'),
      placeholder: I18N.t('form.placeholder.linkedinUrl'),
      validate: yup.string()
        .transform((originalValue) => originalValue === '' ? null : originalValue)
        .nullable()
        .max(4000, I18N.t('SearchOrganisations.registerModal.urlMax', { max: 4000 }))
        .matches(
          /(https?:\/\/)?(?:www\.)?linkedin\.com(?:\\|\/)in(?:\/[a-zA-Z0-9-]+)?/,
          I18N.t('form.validations.invalidUrl'),
        ),
    },
  })
}
