import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { View, Text, Icon, Image } from '@/components'
import { ComponentVariants, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { ProgressCircleComposition, ProgressCircleStyles } from '../../app/stylesheets/ProgressCircle'

type TextProps = PropsOf<typeof Text>
type IconProps = PropsOf<typeof Icon>
type ChildrenProps = {
  progress?: number
}

export type ProgressCircleProps = {
  progress?: number | string
  text?: TextProps['text']
  textProps?: Partial<TextProps>
  icon?: IconProps['name']
  iconProps?: Partial<IconProps>
  image?: string
  innerContentComponent?: ((props: ProgressCircleProps & { animatedProgress: number }) => JSX.Element) | JSX.Element
  styles?: StylesOf<ProgressCircleComposition>
  children?: ((props: ChildrenProps) => JSX.Element) | JSX.Element
  circleProps?: any

} & Omit<PropsOf<typeof View>, 'styles' | 'variants' | 'children'> & ComponentVariants<typeof ProgressCircleStyles>

export const ProgressCircle = (props: ProgressCircleProps) => {
  const {
    text,
    progress,
    icon,
    variants,
    image,
    styles,

    ...otherProps
  } = props

  const variantStyles = useDefaultComponentStyle<'u:ProgressCircle', typeof ProgressCircleStyles>('u:ProgressCircle', {
    variants,
    rootElement: 'wrapper',
    styles,
  })

  const wrapperSize = variantStyles.circle?.size ?? variantStyles.circle?.width ?? variantStyles.circle?.height ?? 0

  const isProgressNumber = TypeGuards.isNumber(progress)

  return (
    <View variants={['alignCenter']} {...otherProps}>
      <CircularProgressbarWithChildren
        value={isProgressNumber ? progress : 0}
        css={{
          width: wrapperSize,
          height: wrapperSize,
        }}
        styles={buildStyles({
          pathColor: variantStyles.line?.borderColor,
          trailColor: variantStyles.line?.backgroundColor,
          strokeLinecap: 'butt',
        })}
      >
        {
          !!icon && <Icon name={icon} color={variantStyles.icon?.color} debugName='baby organisation icon' size={variantStyles.icon?.size} />
        }
        {
          !!image && <Image source={image} style={variantStyles.image} />
        }

        <Text style={variantStyles.text}>{text}</Text>
      </CircularProgressbarWithChildren>

      <View variants={['column', 'marginLeft:2']} style={variantStyles.label}>
        <Text variants={['p3', 'color:neutral5']} text={isProgressNumber ? `${progress}%` : progress} />
        <Text variants={['p4', 'color:neutral9', 'bold']} text={text} />
      </View>
    </View>
  )
}
