import { Settings } from '@/app'
import { APIClient } from '@/services'
import { TypeGuards } from '@codeleap/common'
import React, { useEffect, useState } from 'react'

// NOTE This is the file were you put functions you don't know where else to put

export function displayNameFallback(displayName) {
  return displayName || 'Username'
}

export const transformSnakeCaseToTitleCase = (inputString: string): string => {
  const words: string[] = inputString.split('_')
  const transformedWords: string[] = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  return transformedWords.join(' ')
}

export const urlValidator = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const comingSoon = () => {
  window.alert('Coming soon! Feature not yet implemented.')
}

export function objectPickBy<K extends string = string, P = any>(obj: Record<K, P>, predicate: (valueKey: P, key: K) => boolean) {
  const result = {} as Record<K, P>

  for (const key in obj) {
    if (obj?.hasOwnProperty?.(key) && predicate?.(obj?.[key], key)) {
      result[key] = obj?.[key]
    }
  }

  return result
}

export function objectCopy(obj = {}): Record<string, any> {
  const copy = {
    ...(TypeGuards.isObject(obj) ? obj : {}),
  }

  return copy
}

export const concatStyles = (styles: Array<any>) => {
  let concatenatedStyles = {}

  styles?.forEach(style => {
    if (TypeGuards.isObject(style)) {
      concatenatedStyles = {
        ...concatenatedStyles,
        ...style,
      }
    }
  })

  return concatenatedStyles
}

export function formatNumberToShort(value) {
  if (String(value)?.length <= 3) return value

  const suffixes = ['', 'k', 'M', 'B', 'T']
  const suffixNum = Math?.floor(('' + value)?.length / 3)

  if (suffixNum >= suffixes?.length) {
    return ''
  }

  const shortValue = parseFloat(
    (suffixNum !== 0 ? value / Math?.pow?.(1000, suffixNum) : value)?.toPrecision(3),
  )

  return shortValue + suffixes?.[suffixNum]
}

export const getDimensionsFromFile = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('File is not provided.')
      return
    }

    const url = URL.createObjectURL(file)
    const img = new Image()

    img.onload = function () {
      const dimensions = {
        width: img.width,
        height: img.height,
      }
      URL.revokeObjectURL(img.src)
      resolve(dimensions)
    }

    img.onerror = function () {
      reject('Failed to load image.')
    }

    img.src = url
  })
}

export const ensureHttps = (url: string) => {
  if (!url) return null
  // Check if the URL already has "http://" or "https://"
  if (/^https?:\/\//.test(url)) {
    return url // URL is already valid
  } else {
    // URL doesn't have "http://" or "https://", so add "https://"
    return `http://${url}`
  }
}

export const preloadImages = (images: HTMLImageElement['src'][]) => {
  images?.forEach((img_src) => {
    const img = new Image()

    img.src = img_src
  })
}

type verifyProfanityReturn = { profanities: string[]; isProfanity: boolean }
export function verifyProfanity(text: string): verifyProfanityReturn {
  const profanities = APIClient.Session.QueryKeys.profanityWords.getData()

  const profanityArr = [...new Set<string>(text.split(/\s+/).filter(w => profanities?.includes(w.toLowerCase())))]

  return { profanities: profanityArr, isProfanity: !!profanityArr.length }
}

const emptySpaces = Array(2).fill('\u2003').join('')

export const formatScore = (score: number | null) => {
  if (TypeGuards.isNil(score)) return emptySpaces
  return Math.round(score)
}

export function calcPercentage({ total, partial }: Record<'total' | 'partial', number>) {
  return (partial / total) * 100
}

export const isUsingAdBlocker = async () => {
  if (typeof window === 'undefined') return Promise.resolve(false)
  const url = 'https://www3.doubleclick.net'

  try {
    const res = await fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })

    if (res.redirected) {
      return true
    }

    return false
  } catch (err) {
    return true
  }
}

const youtubeIDLength = 11

export const getYouTubeId = (url: string) => {
  if (typeof url !== 'string' || url?.length <= youtubeIDLength) return null

  const noDots = url.replace(/\./g, '')

  const includesYoutube = noDots.includes('youtube')

  if (!includesYoutube) return null

  let videoId = null

  url = ensureHttps(url)

  try {
    const _url = new URL(url)
    const pathParts = _url.pathname.split('/')

    const path = pathParts[pathParts.length - 1]

    videoId = path?.length == youtubeIDLength ? path : null

    if (!videoId) {
      const searchParams = _url?.searchParams

      const idSearchParam = searchParams.get('v')

      videoId = idSearchParam?.length === youtubeIDLength ? idSearchParam : null
    }
  } catch (err) {}

  return videoId
}

export async function existsYouTubeId(url:string) {
  try {
    const videoId = getYouTubeId(url)

    const url_request = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${Settings.ApiCredentials.Youtube.API_KEY}&part=snippet`

    const response = await fetch(url_request)

    if (response?.ok) {
      const data = await response.json()

      return data?.items && data?.items?.length > 0
    } else {
      return false
    }
  } catch (err) {
    return false
  }
}

export async function getYouTubeThumbnail(videoId:string) {
  try {
    const url_request = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${Settings.ApiCredentials.Youtube.API_KEY}&part=snippet`

    const response = await fetch(url_request)

    const data = await response.json()

    return data?.items[0]?.snippet?.thumbnails?.maxres?.url ?? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  } catch (err) {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
  }
}

const thumbs = {}

export function useYoutubeThumbnail(videoId: string) {
  const [thumbnail, setThumbnail] = useState(null)

  const getThumbnail = async () => {
    if (thumbs[videoId]) return

    const url = await getYouTubeThumbnail(videoId)

    thumbs[videoId] = url
    if (thumbnail !== url) setThumbnail(url)
  }

  useEffect(() => {
    getThumbnail()
  }, [videoId])

  return thumbs?.[videoId] ?? thumbnail ?? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}
