import { graphql } from 'gatsby'
import { ComponentVariants, useDefaultComponentStyle, FormTypes, StylesOf, TypeGuards } from '@codeleap/common'
import React from 'react'
import { AppImageStyles, ImageComposition, ImagePresets } from '../app/stylesheets/Image'
import { CSSInterpolation } from '@emotion/serialize'

type CommonProps = {
  source: string | FormTypes.AnyFile
  css?: CSSInterpolation | CSSInterpolation[]
  alt?: string
  objectFit?: Exclude<React.CSSProperties['objectFit'], React.CSSProperties['objectFit'][]>
  styles?: StylesOf<ImageComposition>
  style?: React.CSSProperties
} & ComponentVariants<typeof AppImageStyles>

type StaticImageProps = {
  type?: 'static'
}

type DynamicImageProps = {
  type?: 'dynamic'
}

export type ImageProps = (StaticImageProps | DynamicImageProps) & CommonProps

export const Image = (imageProps:ImageProps) => {
  const {
    source,
    type = 'static',
    variants = [],
    responsiveVariants = {},
    styles = {},
    objectFit,
    style = {},
    ...props
  } = imageProps

  const variantStyles = useDefaultComponentStyle<'u:Image', typeof ImagePresets>('u:Image', {
    responsiveVariants,
    variants,
    styles,
  })

  const imageFit = TypeGuards.isString(objectFit) && { objectFit }

  return (
    <img
      {...props}
      src={source as HTMLImageElement['src']}
      css={[variantStyles.wrapper, imageFit, style]}
    />
  )
}

const query = graphql`
  query {
    allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        relativePath
      }
    }
  }
`
