import React from 'react'
import { Theme } from '@/app'
import { View, Text, Icon, ProgressBar } from '@/components'
import { ProgressCircle } from '@/components/Progress/Circle'
import { CategoriesResponse, Organisation } from '@/types'
import { formatScore } from '@/utils'
import { TypeGuards } from '@codeleap/common'

type ReviewResultsCardProps = {
  category: CategoriesResponse[string]
  score: Organisation['score']['categories'][string]
  showSubcategories?: boolean
  style?: any
}

export const ReviewResultsCard = (props: ReviewResultsCardProps) => {
  const { category, score, showSubcategories = true, style } = props

  const { name, sub_categories, description } = category

  const subCategoriesArr = Object.values(sub_categories)

  return (
    <View style={[Theme.effects.sectionElevation, style]} variants={['bg:neutral1', 'fullWidth', 'padding:2', 'border-radius:small', 'fullWidth']}>
      <View variants={['column', 'gap:2', 'fullWidth']}>
        <View variants={['gap:2']}>
          <View variants={['column', 'gap:1', 'alignCenter', 'justifyCenter']}>
            <ProgressCircle
              progress={formatScore(score.total)}
              image={category.web_icon || category.mobile_icon}
              variants={['small', 'noLabel']}
            />
            <Text variants={['p2', 'color:neutral6']}>{TypeGuards.isNil(score.total) ? '0%' : `${formatScore(score.total)}%`}</Text>
          </View>
          <View variants={['column', 'gap:1']}>
            <Text variants={['h3', 'color:neutral9']}>{name}</Text>
            <Text variants={['p3', 'color:neutral6']}>
              {description}
            </Text>
          </View>
        </View>
        {showSubcategories && (
          <>
            <View variants={['separator', 'bg:separator', 'fullWidth']} />
            <View variants={['column', 'gap:2']}>
              {
                subCategoriesArr.map((item) => {

                  const subCategoryScore = score.subcategories[item.id]

                  return (
                    <View variants={['column', 'gap:1', 'fullWidth']} key={item.id}>
                      <View variants={['alignCenter', 'justifySpaceBetween', 'fullWidth']}>
                        <View variants={['alignCenter', 'gap:1']}>
                          <Icon name='message-square' url={item.web_icon || item.mobile_icon} variants={['smaller']} />
                          <Text variants={['h5', 'color:neutral6']}>{item.name}</Text>
                        </View>
                        <View>
                          <ProgressBar
                            variants={['medium']}
                            responsiveVariants={{ small: ['small'] }}
                            progress={subCategoryScore}
                            text={formatScore(subCategoryScore)}
                          />
                        </View>
                      </View>
                      <View>
                        <Text variants={['p3', 'color:neutral6']}>
                          {item.description}
                        </Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>
          </>
        )}

      </View>
    </View>
  )
}
