import { React, variantProvider } from '@/app'
import moment from 'moment'
import { Text, View, Icon, Touchable } from '@/components'
import { APIClient } from '@/services'

type PostCardProps = {
  post: APIClient.Post.Post
  remove?: (id: APIClient.Post.Post['id']) => any
  edit?: (() => any) | null
}

const iconSize = 25

export const PostCard: React.FC<PostCardProps> = ({ post, remove, edit }) => {
  const date = moment(post?.created_datetime).format('Do of MMM YY')

  return (
    <View
      variants={['column', 'fullWidth']}
      css={[styles.wrapper]}
    >
      <View variants={['row', 'justifySpaceBetween']}>
        {remove && (
          <Touchable onPress={remove} debugName={'Delete post'}>
            <Icon name={'x'} size={iconSize} />
          </Touchable>
        )}
      </View>

      <View variants={['row', 'justifySpaceBetween']}>
        <Text text={`@${post?.username}`} variants={['h5']} />
        <Text text={`${date}`} variants={['p2']} />
      </View>

      <Text text={post?.title} variants={['h4']} />

      <View variants={['row', 'justifySpaceBetween']}>
        <Text text={post?.content} variants={['p1']} />
        {edit && (
          <Touchable onPress={edit} debugName={'Edit post'} >
            <Icon name={'edit'} size={iconSize} />
          </Touchable>
        )}
      </View>
    </View>
  )
}

export default PostCard

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    padding: theme.spacing.value(2),
    borderRadius: theme.borderRadius.small,
    backgroundColor: theme.colors.neutral2,
    ...theme.presets.column,
    ...theme.spacing.gap(2),
  },
}), true)
