import React, { useRef, useState } from 'react'
import { I18N, Theme, variantProvider } from '@/app'
import { Text, Page, View, SegmentedControl, SearchLabel, Grid, ModuleCard, LearnEmptyPlaceholder, FilterLearn, SearchLearn, AuthenticatedScreen } from '@/components'
import { APIClient } from '@/services'
import { authWall, useIsMobile } from '@/utils'
import { PageProps, graphql } from 'gatsby'
import { StoriesCard } from '@/components/Cards/Stories'
import { onMount, onUpdate, useI18N } from '@codeleap/common'
import { useSearchParams } from '@codeleap/web'
import { AppStatus, shareStoryId } from '@/redux'
import { analytics } from '@/services'

const sectionOptions = [
  {
    label: I18N.t('learn.learnScreen.section.lessons'),
    value: 'lessons',
    debugName: 'select lessons section',
  },
  {
    label: I18N.t('learn.learnScreen.section.stories'),
    value: 'successStories',
    debugName: 'select successStories section',
  },
]

export const sectionIds = {
  lessons: sectionOptions[0].value,
  successStories: sectionOptions[1].value,
}

const storiesSection = sectionOptions[1].value

const SEGMENTED_DEBOUNCE_TIME = 1500

function Learn() {
  const [searchParams, setSearchParams] = useSearchParams({
    section: sectionOptions?.[0]?.value,
    sources: '',
  })

  const [section, setSection] = useState(searchParams?.section)
  const sectionRef = useRef(null)

  const isLessonsSection = section === 'lessons'

  const stories = APIClient.Stories.useStories()
  const sources = APIClient.Learn.useSources()
  const modules = APIClient.Learn.useModules({
    sources: searchParams.sources,
  })

  const isMobile = useIsMobile()

  const { t } = useI18N()

  onMount(() => {

    if (!!searchParams?.storyId) {
      authWall(() => {
        shareStoryId.setState({ id: Number(searchParams?.storyId) })
        setSection(storiesSection)
        AppStatus.setModal('storyDetail')
      })()
    }
  })

  onUpdate(() => {
    const hasFilteredSources = sources?.sourcesOptions?.length > 0
    const isItemsValid = !!sources?.sources?.items
    if ((hasFilteredSources || searchParams.sources !== '') && isItemsValid) {
      setSearchParams({
        ...searchParams,
        sources: sources?.sourcesOptions.map(source => source.id.toString()).join(','),
      })
    }

  }, [sources?.sourcesOptions])

  const handlePressStoryCard = (story) => {
    analytics.track('story_view', { source: 'Learn' })
    setSearchParams({ 'storyId': story?.id, 'section': storiesSection })
    AppStatus.setModal('storyDetail')
    shareStoryId.setState({ id: story?.id })
  }

  const onLike = (id) => {
    stories.stories.actions.toggleLike(id)
  }

  const onSelectTab = (val) => {
    if (sectionRef.current !== null) return

    setSection(val)
    setSearchParams({ 'section': val })

    sectionRef.current = setTimeout(() => {
      clearTimeout(sectionRef.current)
      sectionRef.current = null
    }, SEGMENTED_DEBOUNCE_TIME)
  }

  const renderStoryItem = React.useCallback(({ item }) => {
    const onPress = (item) => {
      analytics.track('case_read_more')
      handlePressStoryCard(item)
    }

    return (
      <StoriesCard
        key={item?.id + 'learn-story'}
        story={item}
        onPress={authWall(() => onPress(item))}
        onToggleLike={authWall(() => onLike(item?.id))}
        showOrganisation={true}
        replies={item.replies}
        tabIndex={0}
      />
    )
  }, [])

  const renderModuleItem = React.useCallback(({ item }) => (
    <ModuleCard key={item?.id + 'learn-lesson'} module={item} tabIndex={0} />
  ), [])

  const spacing = React.useMemo(() => {
    return Theme.spacing.value(isMobile ? 3 : 2)
  }, [isMobile])

  const Tabs = React.useMemo(() => {
    return (
      <SegmentedControl
        debugName='Filter Learn section'
        onValueChange={onSelectTab}
        value={section}
        options={sectionOptions}
        variants={['fullWidth']}
        touchableProps={{ tabIndex: 0 }}
      />
    )
  }, [section])

  const TabsLessons = React.useMemo(() => {
    return (
      <SegmentedControl
        debugName='Filter lessons'
        onValueChange={modules?.setStatus}
        value={modules?.status as any}
        options={modules?.moduleStatus as any}
        variants={['tab', 'marginTop:1', 'marginBottom:4', 'noBreakline']}
        responsiveVariants={{
          small: ['fullWidth', 'marginBottom:2'],
        }}
        style={!isLessonsSection && styles.invisible}
        touchableProps={{ tabIndex: isLessonsSection ? 0 : -1 }}
      />
    )
  }, [modules?.status, isLessonsSection])

  return (
    <>
      <Page
        centerContent={!isMobile}
        showSubHeader
        showFooter={false}
        variants={['section:elevated', 'header:learn']}
        subHeader={
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text text={t('learn.learnScreen.title')} variants={['h0', 'color:neutral1']} />

              {isMobile ? null : (
                <Text
                  text={t('learn.learnScreen.subtitle')}
                  variants={['p1', 'color:neutral1']}
                />
              )}

              {Tabs}
            </View>

            {isMobile ? (
              <View style={styles.filtersWrapper}>
                <View style={styles.filters}>
                  <SearchLearn
                    section={section}
                    value={isLessonsSection ? modules?.search : stories.search}
                    onValueChange={isLessonsSection ? modules?.setSearch : stories.search}
                  />
                  <FilterLearn
                    section={section as any}
                    modules={modules}
                    stories={stories}
                    sources={sources}
                  />
                </View>
              </View>
            ) : null}
          </View>
        }
        pageTitle={t('learn.learnScreen.title')}
      >
        <View style={styles.page}>
          <View style={styles.content}>
            <View variants={['row', 'alignCenter', 'justifySpaceBetween', 'fullWidth']}>
              {TabsLessons}

              {isMobile ? null : (
                <View css={[styles.filtersLarge]}>
                  <SearchLearn
                    section={section}
                    value={isLessonsSection ? modules?.search : stories.search}
                    onValueChange={isLessonsSection ? modules?.setSearch : stories.setSearch}
                  />
                  <FilterLearn
                    section={section as any}
                    modules={modules}
                    stories={stories}
                    sources={sources}
                  />
                </View>
              )}
            </View>

            <SearchLabel search={isLessonsSection ? modules?.search : stories.search} />

            {isLessonsSection ? (
              <Grid
                {...modules.modulesListProps}
                rowItemsSpacing={spacing}
                columnItemsSpacing={spacing}
                debugName='GridLearn:lessons'
                numColumns={isMobile ? 1 : 2}
                renderItem={renderModuleItem}
                placeholder={{
                  ...modules?.modulesListProps.placeholder,
                  renderEmpty: () => <LearnEmptyPlaceholder list='modules' />,
                }}
              />
            ) : (
              <Grid
                {...stories.storiesListProps}
                rowItemsSpacing={spacing}
                columnItemsSpacing={spacing}
                debugName='GridLearn:stories'
                numColumns={isMobile ? 1 : 2}
                renderItem={renderStoryItem}
                placeholder={{
                  ...stories?.storiesListProps?.placeholder,
                  renderEmpty: () => <LearnEmptyPlaceholder list='stories' />,
                }}
              />
            )}
          </View>
        </View>
      </Page>
    </>
  )
}

export default (props) => {
  return (
    <AuthenticatedScreen>
      <Learn {...props} />
    </AuthenticatedScreen>
  )
}

const HEADER_WIDTH = 500
const TAG_HEIGHT = 32

const styles = variantProvider.createComponentStyle(theme => ({
  page: {
    minHeight: '80vh',
    ...theme.presets.column,
    ...theme.presets.alignStart,
    ...theme.presets.justifyStart,
    ...theme.spacing.padding(2),

    [theme.media.down('small')]: {
      ...theme.spacing.padding(0),
      ...theme.spacing.paddingTop(1),
      ...theme.presets.fullWidth,
      ...theme.presets.alignCenter,
    },
  },
  content: {
    ...theme.presets.fullWidth,
    ...theme.presets.column,
    ...theme.presets.alignStart,
    ...theme.presets.justifyStart,

    [theme.media.down('small')]: {
      width: theme.presets.calcWidth(theme.spacing.value(4)),
      maxWidth: theme.presets.calcWidth(theme.spacing.value(4)),
      ...theme.presets.alignCenter,
    },
  },
  header: {
    maxWidth: HEADER_WIDTH,
    ...theme.presets.column,
    ...theme.spacing.gap(3),

    [theme.media.down('small')]: {
      maxWidth: '100vw',
      ...theme.presets.center,
      ...theme.spacing.gap(1),
    },
  },
  headerContent: {
    ...theme.presets.column,
    ...theme.spacing.gap(3),

    [theme.media.down('small')]: {
      ...theme.spacing.gap(1),
      width: theme.presets.calcWidth(theme.spacing.value(4)),
    },
  },
  filtersWrapper: {
    width: '100vw',
    overflow: 'visible',
    position: 'relative',
    height: TAG_HEIGHT + theme.spacing.value(1.5),
    overflowX: 'scroll',
    overflowY: 'hidden',
    marginTop: theme.spacing.value(1),

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'visible',
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: theme.spacing.value(2),
    marginRight: theme.spacing.value(2),
  },
  filtersLarge: {
    height: TAG_HEIGHT,
    maxHeight: TAG_HEIGHT,
    overflow: 'hidden',
  },
  filtersLargeStories: {
    marginLeft: 'auto',
  },
  masonry: {
    ...theme.spacing.marginTop(4),
  },
  invisible: {
    opacity: '0',
    pointerEvents: 'none',

    [theme.media.down('small')]: {
      display: 'none',
    },
  },
}), true)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
