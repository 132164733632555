import { View, GradientModal, Text } from '@/components'
import { shareOrganisation } from '@/utils'
import { ClimateEngagementScore } from '../../'
import { ReviewResultsCard } from '../Cards'
import { APIClient } from '@/services'
import { useAppI18N, variantProvider, React } from '@/app'

export const ScoreModal = (props) => {
  const { visible, toggle, organisation } = props
  const { categories } = APIClient.Organisations.useCategories()
  const { t } = useAppI18N()

  const categoriesArr = Object.values(categories)

  return (
    <GradientModal
      toggle={() => toggle(!visible)}
      visible={visible}
      title={t('organisations.climateEmpowermentScore')}
      onSharePress={() => shareOrganisation(organisation)}
      debugName={'GradientScoreModal'}
      bodyStyle={styles.body}
      boxStyle={styles.box}
      headerStyle={styles.header}
    >
      <>
        <Text variants={['p1']} text={t('organisations.climateEmpowermentScoreDescription')} />
        <View style={styles.wrapper}>
          <View variants={['column', 'gap:2', 'center', 'fullWidth', 'marginBottom:4']} responsiveVariants={{ small: ['marginBottom:2'] }}>
            <ClimateEngagementScore variant={'withoutModal'} organisation={organisation} />
          </View>
          {categoriesArr.map((item) => (
            <ReviewResultsCard key={item.id} category={item} score={organisation.score.categories[item.id]} />
          ))}
        </View>
      </>
    </GradientModal>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  body: {
    overflow: 'auto',
    maxHeight: `calc(100dvh - 56px)`,

    [theme.media.up('small')]: {
      padding: theme.spacing.value(4),
      paddingTop: theme.spacing.value(0),
    },
  },
  header: {
    [theme.media.up('small')]: {
      paddingLeft: theme.spacing.value(4),
      paddingRight: theme.spacing.value(4),
    },
  },
  box: {
    [theme.media.up('small')]: {
      padding: theme.spacing.value(0),
      paddingTop: theme.spacing.value(4),
    },
  },
  wrapper: {
    ...theme.presets.column,
    ...theme.spacing.gap(2),
    ...theme.spacing.marginTop(3),
    maxWidth: 'calc(100vw - 32px)',
  },
}), true)
