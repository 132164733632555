import { CSSProperties } from 'react'
import { variantProvider } from '../theme'

export type PageComposition =
  | 'wrapper'
  | 'subHeader'
  | 'innerWrapper'
  | 'topBorders'
  | 'backgroundImage'
  | 'subHeaderChildren'
  | 'subHeaderBackground'
  | 'subHeaderChildren'
  | 'subHeader:visible'
  | 'subHeader:hidden'

const createPageStyle = variantProvider.createVariantFactory<PageComposition>()

export const PageStyles = {
  default: createPageStyle((theme) => {
    const subHeaderBackgroundStyles = { ...theme.presets.fullWidth,
      ...theme.presets.absolute,
      objectFit: 'cover' as CSSProperties['objectFit'],
      left: 0 }

    return {
      wrapper: {
        minHeight: '100vh',
        ...theme.presets.fullWidth,
        backgroundColor: theme.colors.background,
      },
      subHeaderBackground: {
        ...subHeaderBackgroundStyles,
        height: '40svh',
        top: 0,
      },
      'subHeaderBackground:mobile': {
        ...subHeaderBackgroundStyles,
        height: '60svh',
        top: -(theme.values.headerHeight + theme.spacing.value(2)),
      },
      subHeaderChildren: {
        ...theme.presets.fullWidth,
        ...theme.presets.relative,
        paddingTop: theme.spacing.value(5),
        paddingBottom: theme.spacing.value(5),
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
        zIndex: 1,
      },
      subHeader: {
        ...theme.presets.relative,
        ...theme.presets.fullWidth,
        transition: 'max-height 0.3s ease-in-out',
      },
      'subHeader:visible': {
        maxHeight: 1000,
        overflow: 'unset',
      },
      'subHeader:hidden': {
        maxHeight: 0,
        overflow: 'hidden',
      },
      innerWrapper: {
        zIndex: 1,
        ...theme.presets.relative,
        backgroundColor: theme.colors.background,
        transition: 'border-radius 0.1s ease-in-out',
        transitionDelay: '0.3s',
      },
      topBorders: {
        borderTopLeftRadius: theme.borderRadius.medium,
        borderTopRightRadius: theme.borderRadius.medium,
      },
      backgroundImage: {
        width: '100%',
        minHeight: '100vh',
        position: 'relative',
      },
    }
  }),
  'content:center': createPageStyle(theme => ({
    'innerWrapper': {
      ...theme.presets.column,
    },
  })),
  'safeArea:bottom': createPageStyle(theme => ({
    'innerWrapper': {
      ...theme.spacing.paddingBottom(4),
    },
  })),
  profile: createPageStyle(() => ({})),
  'header:chapter': createPageStyle((theme) => ({
    'wrapper': {
      backgroundColor: theme.colors.neutral1,
    },
    'subHeader:visible': {
      backgroundColor: theme.colors.neutral1,
    },
    subHeaderChildren: {
      paddingBottom: theme.spacing.value(4),

      [theme.media.down('mid')]: {
        paddingTop: theme.spacing.value(1),
        paddingBottom: theme.spacing.value(3),
      },
    },
    innerWrapper: {
      ...theme.effects.smooth,
    },
  })),
  'section:elevated': createPageStyle((theme) => ({
    innerWrapper: {
      ...theme.effects.smooth,
    },
  })),
  'header:learn': createPageStyle((theme) => ({
    subHeaderChildren: {
      paddingTop: theme.spacing.value(5),
      paddingBottom: theme.spacing.value(5),

      [theme.media.down('small')]: {
        paddingTop: theme.spacing.value(2),
        paddingBottom: theme.spacing.value(0.5),
      },
    },
    innerWrapper: {
      ...theme.effects.smooth,
    },
  })),
  'header:act': createPageStyle((theme) => ({
    subHeaderChildren: {
      paddingTop: theme.spacing.value(4),
      paddingBottom: theme.spacing.value(4),

      [theme.media.down('small')]: {
        paddingTop: theme.spacing.value(1),
        paddingBottom: theme.spacing.value(3),
      },
    },
    innerWrapper: {
      ...theme.effects.smooth,
    },
  })),
  'content:page': createPageStyle((theme) => ({
    innerWrapper: {
      ...theme.presets.column,
      ...theme.effects.smooth,
      ...theme.spacing.paddingVertical(4),
      minHeight: '80svh',

      [theme.media.down('small')]: {
        ...theme.spacing.paddingVertical(2),
      },
    },
    subHeaderChildren: {
      [theme.media.down('small')]: {
        paddingTop: theme.spacing.value(3),
        paddingBottom: theme.spacing.value(4),
      },
    },
  })),
  'page:organisation': createPageStyle((theme) => ({
    subHeaderChildren: {
      [theme.media.down('small')]: {
        paddingTop: theme.spacing.value(3),
        paddingBottom: theme.spacing.value(2),
      },
    },
  })),
}
